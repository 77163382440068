import { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";

import { createTimestampFromDateComponents, getDateComponents, getTimeFieldStringFromTimestamp, isTimestampOnGivenDate } from "../../../utils/datetime";

import translationKeys from '../../../translations/keys';

import { Flex } from "../../layout";
import { Calendar } from "../Picker";
import { TimeFieldErrorMessage, TimeInputField } from "./DateTimePicker.styles";
import TimezoneSelector from "../../timezone-selector/TimezoneSelector";
import { DateDisplay, DateRangeSelector } from "../date-time-range-picker/DateTimeRangePicker.styles";


const DateTimePicker = ({
    value,
    onChange,
    min,
    max,
    timezone,
    timezoneSelectorProps,
    t
}) => {
    const [timeInputFieldValue, setTimeInputFieldValue] = useState(getTimeFieldStringFromTimestamp(value, timezone));

    useEffect(() => {
        setTimeInputFieldValue(getTimeFieldStringFromTimestamp(value, timezone));
    }, [timezone]);

    // Either true or an error message to show if time field input value is invalid
    const timeFieldError = useMemo(() => {
        if (!timeInputFieldValue) {
            return true;
        }

        const currentValue = getNewValueWithTimeField(value, timeInputFieldValue, timezone);

        if (currentValue < min) {
            return t(translationKeys.time.validation.MUST_BE_AFTER_X, { date: getTimeFieldStringFromTimestamp(min, timezone) });
        }

        if (currentValue > max) {
            return t(translationKeys.time.validation.MUST_BE_BEFORE_X, { date: getTimeFieldStringFromTimestamp(max, timezone) });
        }

        return false;
    }, [value, min, max, timeInputFieldValue, t]);

    return (
        <Flex
            column
            alignItems="centre"
        >
            <Calendar
                defaultDateInView={value}
                minDateTime={min}
                maxDateTime={max}
                isDateSelected={date => isTimestampOnGivenDate(value, date, timezone)}
                onDateClick={newDate => {
                    const [hour, minute] = timeInputFieldValue.split(':');
                    const newValue = createTimestampFromDateComponents(newDate.year, newDate.month, newDate.day, hour, minute, timezone, true);
                    // If new value is outside valid range due to time field, update time field
                    if (min && newValue < min) {
                        setTimeInputFieldValue(getTimeFieldStringFromTimestamp(min, timezone));
                        onChange(min);
                    } else if (max && newValue > max) {
                        setTimeInputFieldValue(getTimeFieldStringFromTimestamp(max, timezone));
                        onChange(max);
                    } else {
                        onChange(newValue);
                    }
                }}
                timezone={timezone}
            />
            <Flex
                column
                gap={10}
                justifyContent={timezone && timezoneSelectorProps ? 'space-between' : 'center'}
                alignItems="centre"
                style={{width: '100%', marginTop: 20, padding: '0 10px'}}
            >
                {timezone && <TimezoneSelector {...timezoneSelectorProps} variant="select" style={{width: '100%'}}/>}
                <div style={{width: '100%', display: 'grid', gridAutoFlow: 'column', gridAutoColumns: '1fr'}}>
                <DateDisplay style={{height: 27}}>
                    {value ? new Date(value).toLocaleDateString(undefined, { timeZone: timezone }) : '--/--/----'}
                </DateDisplay>
                <TimeInputField
                    value={timeInputFieldValue}
                    onChange={event => {
                        const newTimeString = event.target.value;
                        setTimeInputFieldValue(newTimeString);
                        
                        // Only invoke `onChange` if new value is valid
                        if (newTimeString) {
                            const possibleNewValue = getNewValueWithTimeField(value, newTimeString, timezone);

                            if (possibleNewValue >= min && possibleNewValue <= max) {
                                onChange(possibleNewValue);
                            }
                        }
                    }}
                    required
                    aria-label={t(translationKeys.time.TIME)}
                    aria-invalid={!!timeFieldError}
                    style={{maxWidth: 200, borderLeft: 'none'}}
                />
                </div>
            </Flex>
            <TimeFieldErrorMessage>
                {
                    typeof timeFieldError === 'string' && timeFieldError
                }
            </TimeFieldErrorMessage>
        </Flex>
    );
}

const getNewValueWithTimeField = (currentValue, timeFieldString, timezone) => {
    const [hour, minute] = timeFieldString.split(':');
    const { year, month, day } = getDateComponents(currentValue, timezone);
    const newValue = createTimestampFromDateComponents(year, month, day, hour, minute, timezone, true);
    return newValue;
};


export default withTranslation()(DateTimePicker);