import styled from 'styled-components';
import { FADED_GREY, VL_BLACK, WHITE } from '../../../utils/colours';
import { Flex } from '../../layout';

export const MonthHeader = styled.span`
    font-weight: 300;
    color: ${FADED_GREY};
    width: 150px;
    text-align: center;
    overflow: hidden;
`;

MonthHeader.displayName = 'MonthHeader';

export const MonthHeaderButton = styled.button`
    background: none;
    padding: 0;
    margin: 0;
    border: none;
    font-weight: 100;
    color: ${FADED_GREY};
    display: inline-flex;
    align-items: center;
    justify-content: center;

    :not(:disabled) {
        cursor: pointer;
    }

    :disabled {
        opacity: 0;
    }

    svg {
        height: 12px;
        width: auto;
    }
`;

MonthHeaderButton.displayName = 'MonthHeaderButton';

export const CalendarGrid = styled.div`
    display: grid;
    width: 100%;
    max-width: 400px;
    grid-template-columns: repeat(7, 14.29%);
    grid-template-rows: 20px repeat(6, 40px);
    margin-top: 15px;
    justify-content: center;

    font-weight: 100;
    font-size: 80%;
    text-align: center;
    color: ${FADED_GREY};

    // Grid slightly wider than container - not sure why?
    overflow-x: hidden;
`;

CalendarGrid.displayName = 'CalendarGrid';

export const DateCell = styled.div`
    padding: 5px;
    position: relative;
    z-index: 0;
    height: 40px;
    width: 100%;
    margin: auto;

    ${({ $showStartTrail, $colour, $leftEdge }) =>
        $showStartTrail
            ? `
        ::before {
            content: "";
            position: absolute;
            background: ${$colour};
            height: calc(100% - 10px);
            width: 50%;
            top: 5px;
            left: 0;
            z-index: -1;
            opacity: 0.4;
            ${
                $leftEdge
                    ? 'border-radius: 50px 0 0 50px; width: calc(50% - 5px); left: 5px;'
                    : ''
            }
        }
    `
            : ''}

    ${({ $showEndTrail, $colour, $rightEdge }) =>
        $showEndTrail
            ? `
        ::after {
            content: "";
            position: absolute;
            background: ${$colour};
            height: calc(100% - 10px);
            width: 50%;
            top: 5px;
            right: 0;
            z-index: -1;
            opacity: 0.4;
            ${
                $rightEdge
                    ? 'border-radius: 0 50px 50px 0; width: calc(50% - 5px); right: 5px;'
                    : ''
            }
        }
    `
            : ''}
`;

DateCell.displayName = 'DateCell';

export const DateButton = styled.button`
    background: ${({ $selected, $colour }) => ($selected ? $colour : 'none')};
    border: 1px solid
        ${({ $selected, $colour }) => ($selected ? $colour : '#fff0')};
    padding: 0;
    margin: 0;
    font-weight: ${({ $isToday }) => ($isToday ? 300 : 100)};
    aspect-ratio: 1;
    height: 100%;
    text-align: center;
    cursor: pointer;
    color: ${({ $inSelectedMonth, $selected }) =>
        $selected ? '#fff' : $inSelectedMonth ? '#000' : FADED_GREY};
    border-radius: 50px;
    transition: border-color 0.2s;

    @media (hover: hover) {
        :hover:not(:disabled) {
            border-color: ${({ $colour }) => $colour};
        }
    }

    :disabled {
        opacity: 0.2;
        cursor: not-allowed;
    }
`;

DateButton.displayName = 'DateButton';

export const RangeRow = styled.div`
    display: grid;
    grid-template-columns: 50px 1fr 1fr;
    grid-template-rows: auto;
    margin: 0;
    font-size: 90%;
    align-items: center;
    gap: 10px;
`;

RangeRow.displayName = 'DateRangeRow';

export const RangeRowLabel = styled.span`
    color: #000;
    font-weight: 300;
    text-align: left;
`;

RangeRowLabel.displayName = 'DateRangeRowLabel';

export const DateRangeSelector = styled.button`
    background: ${WHITE()};
    padding: 0 5px;
    margin: 0;
    border: 1px solid
        ${({ $selected, $colour }) => ($selected ? $colour : VL_BLACK())};
    font-weight: 100;
    cursor: pointer;
    width: 100%;
    height: 24px;
`;

DateRangeSelector.displayName = 'DateRangeSelector';

export const DateDisplay = styled(Flex).attrs({ centre: true })`
    background: ${WHITE()};
    border: 1px solid ${VL_BLACK()};
    width: 100%;
    font-weight: 100;
`;

export const TimeInputField = styled.input.attrs({
    type: 'time',
})`
    font-weight: 100;
    border: 1px solid ${FADED_GREY};
    width: 100%;
    height: 24px;

    &[aria-invalid="true"] {
        border-color: red;
    }
`;

TimeInputField.displayName = 'TimeInput';


export const Text = styled.span`
    font-weight: 100;
    font-size: 90%;
    margin-top: 20px;
`;

Text.displayName = 'Text';