import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import translationKeys from '../../translations/keys';

import { createDateAsTimezone, getDateComponents } from '../../utils/datetime';
import { VL_RED } from '../../utils/colours';

import useWhiteLabelComponent from '../../hooks/useWhiteLabelComponent';

import { ReactComponent as PaginationBackIcon } from '../../assets/images/icons/pagination-back.svg';
import { ReactComponent as PaginationForwardIcon } from '../../assets/images/icons/pagination-forward.svg';

import Button from '../button';
import Span from '../span';
import { Flex, ButtonsRow } from '../layout';
import {
    CalendarGrid,
    MonthHeader,
    DateButton,
    MonthHeaderButton,
    DateCell,
    RangeRow,
    RangeRowLabel,
    TimeInputField,
    Text,
} from './Picker.styles';

const months = [
    {
        name: 'January',
        translationKey: translationKeys.time.months.JANUARY,
    },
    {
        name: 'February',
        translationKey: translationKeys.time.months.FEBRUARY,
    },
    {
        name: 'March',
        translationKey: translationKeys.time.months.MARCH,
    },
    {
        name: 'April',
        translationKey: translationKeys.time.months.APRIL,
    },
    {
        name: 'May',
        translationKey: translationKeys.time.months.MAY,
    },
    {
        name: 'June',
        translationKey: translationKeys.time.months.JUNE,
    },
    {
        name: 'July',
        translationKey: translationKeys.time.months.JULY,
    },
    {
        name: 'August',
        translationKey: translationKeys.time.months.AUGUST,
    },
    {
        name: 'September',
        translationKey: translationKeys.time.months.SEPTEMBER,
    },
    {
        name: 'October',
        translationKey: translationKeys.time.months.OCTOBER,
    },
    {
        name: 'November',
        translationKey: translationKeys.time.months.NOVEMBER,
    },
    {
        name: 'December',
        translationKey: translationKeys.time.months.DECEMBER,
    },
];

const days = [
    {
        abbreviation: 'Mon',
        translationKey: translationKeys.time.days.monday.ABBREVIATION,
    },
    {
        abbreviation: 'Tue',
        translationKey: translationKeys.time.days.tuesday.ABBREVIATION,
    },
    {
        abbreviation: 'Wed',
        translationKey: translationKeys.time.days.wednesday.ABBREVIATION,
    },
    {
        abbreviation: 'Thu',
        translationKey: translationKeys.time.days.thursday.ABBREVIATION,
    },
    {
        abbreviation: 'Fri',
        translationKey: translationKeys.time.days.friday.ABBREVIATION,
    },
    {
        abbreviation: 'Sat',
        translationKey: translationKeys.time.days.saturday.ABBREVIATION,
    },
    {
        abbreviation: 'Sun',
        translationKey: translationKeys.time.days.sunday.ABBREVIATION,
    },
];

const Picker = withTranslation()(({
    defaultDateInView,
    minDateTime,
    maxDateTime,
    showStartTrail,
    showEndTrail,
    isDateDisabled,
    isDateSelected,
    onDateClick,
    onSubmit,
    disableSubmissions,
    onClear,
    selectionMade,
    renderRangeRowComponents,
    renderTimezoneComponent,
    startLabel,
    endLabel,
    timezone,
    text,
    t
}) => {

    const timezoneComponent = renderTimezoneComponent();
    return (
        <Flex
            column
            alignItems="centre"
            style={{ maxHeight: '100%', overflow: 'hidden auto' }}
            gap={10}
        >
            {
                onClear && (
                    <Flex
                        justifyContent="flex-end"
                        style={{
                            width: '100%'
                        }}
                    >
                        <Button
                            variant="text"
                            onClick={onClear}
                            disabled={!selectionMade}
                            style={{
                                color: VL_RED,
                                fontSize: '80%'
                            }}
                        >
                            {t ? t(translationKeys.forms.CLEAR) : 'Clear'}
                        </Button>
                    </Flex>
                )
            }
            <Calendar
                defaultDateInView={defaultDateInView}
                minDateTime={minDateTime}
                maxDateTime={maxDateTime}
                showStartTrail={showStartTrail}
                showEndTrail={showEndTrail}
                isDateDisabled={isDateDisabled}
                isDateSelected={isDateSelected}
                onDateClick={onDateClick}
                timezone={timezone}
            />
            {timezoneComponent && <Flex row alignItems="center" gap={10} style={{width: '100%', fontSize: '90%'}}>
                {timezoneComponent}
            </Flex>}

            {/* Start of range */}
            <RangeRow>
                <RangeRowLabel>{startLabel ?? (t ? t(translationKeys.time.START) : 'Start')}</RangeRowLabel>
                {renderRangeRowComponents(false)}
            </RangeRow>

            {/* End of range */}
            <RangeRow>
                <RangeRowLabel>{endLabel ?? (t ? t(translationKeys.time.END) : 'End')}</RangeRowLabel>
                {renderRangeRowComponents(true)}
            </RangeRow>

            {
                text && (
                    <Text>
                        {text}
                    </Text>
                )
            }

            {/* "Apply button". We're moving away from this but leaving code here to support older date picker components that we no longer use but may do again in future. */}
            {
                onSubmit && (
                    <ButtonsRow centre style={{ marginTop: 0 }}>
                        <Button
                            onClick={onSubmit}
                            disabled={disableSubmissions}
                        >
                            {t ? t(translationKeys.forms.APPLY) : 'Apply'}
                        </Button>
                    </ButtonsRow>
                )
            }
            
        </Flex>
    );
});

Picker.propTypes = {
    /** Any date from the month the picker should default to showing. */
    defaultDateInView: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.number
    ]),
    /** Minimum allowed datetime. Only affects dates which can be selected, not times. */
    minDateTime: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.number,
        PropTypes.oneOf(['now'])
    ]),
    /** Maximum allowed datetime. Only affects dates which can be selected, not times. */
    maxDateTime: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.number,
        PropTypes.oneOf(['now'])
    ]),
    /** Function taking Date and returning whether this date should show trail behind left half. */
    showStartTrail: PropTypes.func,
    /** Function taking Date and returning whether this date should show trail behind right half. */
    showEndTrail: PropTypes.func,
    /** Function taking Date and returning whether button for this date should be disabled. */
    isDateDisabled: PropTypes.func,
    /** Function taking Date and returning whether this date should show as selected. */
    isDateSelected: PropTypes.func,
    /** Function called when date button clicked. Takes Date as only argument. */
    onDateClick: PropTypes.func,
    /** Function called when "Apply" button clicked. */
    onSubmit: PropTypes.func,
    /** Disabled "Apply" button. */
    disableSubmissions: PropTypes.bool,
    /** If provided, "Clear" button will show and this function is called when its clicked. */
    onClear: PropTypes.func,
    /** Function returning the two components to render in the range limit row. */
    renderRangeLimitDateComponent: PropTypes.func,
};

export const Calendar = withTranslation()(({
    defaultDateInView,
    minDateTime,
    maxDateTime,
    showStartTrail,
    showEndTrail,
    isDateDisabled,
    isDateSelected,
    onDateClick,
    t,
    i18n: { format: formatDate },
    timezone = 'UTC'  // Default timezone if none is provided
}) => {
    const [selectedDateInView, setSelectedDateInView] = useState(null);

    const dateInView = selectedDateInView ?
        selectedDateInView :
        defaultDateInView ?
            createDateAsTimezone(defaultDateInView, timezone) :
            createDateAsTimezone(new Date(), timezone);

    const changeMonth = (moveForward) => () => {
        const newDate = createDateAsTimezone(dateInView, timezone);
        newDate.setMonth(getDateComponents(newDate, timezone).month + (moveForward ? 1 : -1));
        setSelectedDateInView(newDate.getTime());
    };

    const { accentColour, accentColour2 } = useWhiteLabelComponent();

    const requiresSixRows = useMemo(() => {
        if (!minDateTime || !maxDateTime) {
            return true;
        }
        const minDateTimeComponents = getDateComponents(minDateTime, "UTC");
        const maxDateTimeComponents = getDateComponents(maxDateTime, "UTC");
        const iterations = (maxDateTimeComponents.month - minDateTimeComponents.month) + 12 * (maxDateTimeComponents.year - minDateTimeComponents.year);

        let month = minDateTimeComponents.month;
        let year = minDateTimeComponents.year;
        for (let i = 0; i < iterations; i++) {
            const numberOfDaysInMonth = getDateComponents(new Date(year, month, 0, 23, 59, 59), "UTC").day;
            const startDayX = getDateComponents(new Date(year, month + 1, 1), "UTC");
            const startDay = ( startDayX.dayOfWeek || 7) - 1;
            if (startDay + numberOfDaysInMonth > 35) {
                return true;
            }
            month++;
            if (month > 11) {
                month = 0;
                year++;
            }
        }
        return false;
    }, [minDateTime, maxDateTime, timezone]);

    const isThisDateDisabled = (date, timezone) => {
        let isEarlierThanMinDate, isLaterThanMaxDate;
        if (minDateTime) {
            const minDateComponents = getDateComponents(minDateTime, timezone);
            isEarlierThanMinDate = 
                (
                    date.year < minDateComponents.year || 
                    (date.year <= minDateComponents.year && date.month < minDateComponents.month) || 
                    (date.year <= minDateComponents.year && date.month <= minDateComponents.month && date.day < minDateComponents.day)
                );
        }
        if (maxDateTime) {
            const maxDateComponents = getDateComponents(maxDateTime, timezone);
            isLaterThanMaxDate = 
                (
                    date.year > maxDateComponents.year || 
                    (date.year >= maxDateComponents.year && date.month > maxDateComponents.month) || 
                    (date.year >= maxDateComponents.year && date.month >= maxDateComponents.month && date.day > maxDateComponents.day)
                );
        }
        return isEarlierThanMinDate || isLaterThanMaxDate || isDateDisabled?.(date);
    }

    const checkIfToday = (dateYear, dateMonth, dateDay, timezone) => {
        const formatter = new Intl.DateTimeFormat("en-US", { timeZone: timezone, year: 'numeric', month: '2-digit', day: '2-digit'});
        const [month,, day,, year] = formatter.formatToParts(new Date()).map(part => part.value);
        return (dateYear === parseInt(year) && dateMonth === (parseInt(month) - 1) && dateDay === parseInt(day));
    }
    
    return (
        <>
            {/* Header row */}
            <Flex justifyContent="space-between" gap={20} style={{width: '100%'}}>
                <MonthHeaderButton
                    disabled={
                        minDateTime &&
                        getLastDateOfPreviousMonth(dateInView).getTime() < minDateTime
                    }
                    onClick={changeMonth(false)}
                    aria-label={
                        t
                            ? t(translationKeys.time.PREVIOUS_MONTH)
                            : 'Previous month'
                    }
                >
                    <PaginationBackIcon />
                </MonthHeaderButton>
                <MonthHeader>
                    {t
                        ? t(months[getDateComponents(dateInView, timezone).month].translationKey)
                        : months[getDateComponents(dateInView, timezone).month].name}{' '}
                    {getDateComponents(dateInView, timezone).year}
                </MonthHeader>
                <MonthHeaderButton
                    disabled={
                        maxDateTime &&
                        getFirstDateOfNextMonth(dateInView) > maxDateTime
                    }
                    onClick={changeMonth(true)}
                    aria-label={
                        t ? t(translationKeys.time.NEXT_MONTH) : 'Next month'
                    }
                    $directionNext
                >
                    <PaginationForwardIcon />
                </MonthHeaderButton>
            </Flex>

            <CalendarGrid
                $requiresSixRows={requiresSixRows}
            >
                {/* Days */}
                {days.map(({ abbreviation, translationKey }) => (
                    <Span key={abbreviation}>
                        {t ? t(translationKey) : abbreviation}
                    </Span>
                ))}

                {/* Grid of dates */}
                {getGridDates(
                    getDateComponents(dateInView, timezone).month,
                    getDateComponents(dateInView, timezone).year,
                    timezone
                ).map((date, index) => {
                    const inSelectedMonth =
                        date.month === getDateComponents(dateInView, timezone).month &&
                        date.year === getDateComponents(dateInView, timezone).year;

                    return (
                        <DateCell
                            $colour={accentColour2 ?? accentColour}
                            
                            $showStartTrail={showStartTrail?.(date)}
                            $showEndTrail={showEndTrail?.(date)}
                            $leftEdge={index % 7 === 0}
                            $rightEdge={(index - 6) % 7 === 0}
                            key={date.timestamp}
                        >
                            <DateButton
                                aria-label={`${date.day.toString().padStart(2, "0")}/${(date.month + 1).toString().padStart(2, "0")}`}
                                $inSelectedMonth={inSelectedMonth}
                                disabled={isThisDateDisabled(date, timezone)}
                                onClick={() => {
                                    onDateClick?.(date);
                                    if (
                                        !inSelectedMonth &&
                                        date.timestamp > dateInView
                                    ) {
                                        setSelectedDateInView(date.timestamp);
                                    }
                                }}
                                $selected={isDateSelected?.(date)}
                                $colour={accentColour2 ?? accentColour}
                                $isToday={checkIfToday(date.year, date.month, date.day, timezone)}
                            >
                                {date.day}
                            </DateButton>
                        </DateCell>
                    );
                })}
            </CalendarGrid>
        </>
    )
});
const getFirstDateInMonth = (month, year) => {
    return Date.UTC(year, month, 1);  // Direct UTC date creation
};

const getGridStartDate = (month, year, timezone) => {
    const firstDateInMonth = getFirstDateInMonth(month, year, timezone);
    const { dayOfWeek } = getDateComponents(firstDateInMonth, 'UTC');
    const x = Date.UTC(year, month, 1 - ((dayOfWeek || 7) - 1))
    return x;
};

const getGridDates = (month, year, timezone) => {
    const gridStartDate = getGridStartDate(month, year, timezone);
    const {year: startYear, month: startMonth, day: startDay} = getDateComponents(gridStartDate, 'UTC');
    const dates = [];
    
    for (let i = 0; i < 42; i++) {
        // Compute the new date using the start date's year, month, and day incremented by i days
        // Using Date.UTC to ensure the timezone is consistently handled as UTC
        let newDate = Date.UTC(startYear, startMonth, startDay + i, 0, 0, 0, 0);
        
        // Ensure the timezone is applied when retrieving components
        const { year, month: newMonth, day, dayOfWeek } = getDateComponents(newDate, 'UTC');
        
        // Break the loop if the 6th row's first cell isn't needed
        if (i === 35 && newMonth !== month) {
            break;
        }
        
        dates.push({
            timestamp: newDate,
            year,
            month: newMonth,
            day,
            dayOfWeek,
            hour: 0,
            minute: 0
        });
    }
    return dates;
};
const getLastDateOfPreviousMonth = (date, timezone) => {
    const { year, month } = getDateComponents(date, timezone);
    // To get the last date of the previous month, set the day to 0 in JavaScript which translates to the last day of the previous month
    return new Date(Date.UTC(year, month, 0)); // UTC time to avoid timezone shifts
};
const getFirstDateOfNextMonth = (date, timezone) => {
    const { year, month } = getDateComponents(date, timezone);
    // Incrementing the month by one with day set to 1 gives the first day of the next month
    return new Date(Date.UTC(year, month + 1, 1)); // UTC time to avoid timezone shifts
};

export default Picker;

export const TimeField = withTranslation()(({ time, setTime, label, invalid = false, isEndTime = false, onEnterKeyPressed, t }) => {

    let ariaLabel = label;
    if (!ariaLabel) {
        if (isEndTime) {
            ariaLabel = t ? t(translationKeys.time.END_TIME) : 'End time';
        } else {
            ariaLabel = t ? t(translationKeys.time.START_TIME) : 'Start time';
        }
    }

    const defaultTime = isEndTime ? '23:59' : '00:00';

    return (
        <TimeInputField
            value={time}
            // Has defaultTime in case user can clear input like on Firefox, though `required` prop should stop that anyway
            // Got `defaultTime` and `required` just to be doubly safe
            onChange={(e) => setTime(e.target.value || defaultTime)}
            required
            aria-label={ariaLabel}
            aria-invalid={invalid}
            onKeyUp={onEnterKeyPressed ? event => {
                if (event.key === 'Enter') {
                    onEnterKeyPressed();
                }
            } : undefined}
        />
    );
});

TimeField.displayName = 'TimeField';

TimeField.propTypes = {
    /** Value of input field. */
    time: PropTypes.string.isRequired,
    /** setState function for updating value. */
    setTime: PropTypes.func.isRequired,
    /** aria-label for input field. */
    label: PropTypes.string,
    /** Is value of this field valid? */
    invalid: PropTypes.bool,
    /** Is this the end time of a range (as opposed to start time)? */
    isEndTime: PropTypes.bool,
    /** Callback invoked when user clicks Enter key when focus is on input field. */
    onEnterKeyPressed: PropTypes.func
};