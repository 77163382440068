import styled from 'styled-components';
import { VL_BLACK, WHITE } from '../../../utils/colours';

export const LabelAndInputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

LabelAndInputContainer.displayName = 'LabelAndInputContainer';

export const InputContainer = styled.input`
  width: ${({ large }) => (large ? 15 : 12)}px;
  height: ${({ large }) => (large ? 15 : 12)}px;
  min-width: ${({ large }) => (large ? 15 : 12)}px;
  min-height: ${({ large }) => (large ? 15 : 12)}px;
  border-radius: 50%;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  appearance: none;
  background-color: ${({ disabled, variant }) =>
    disabled ? variant === 'light' ? VL_BLACK(20) : WHITE(25) : '#b6b6b6'};
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:checked {
    background-color: ${({ accentColour }) => accentColour};
  }
  
  &:checked::after {
    content: '';
    position: absolute;
    width: ${({ large }) => (large ? 3 : 2)}px;
    height: ${({ large }) => (large ? 8 : 6)}px;
    border: solid ${({ whiteCheck }) => (whiteCheck ? WHITE() : VL_BLACK())};
    border-width: 0 2px 2px 0;
    border-radius: 1px;
    transform: rotate(45deg);
    left: ${({ large }) => (large ? 5 : 4)}px;
    top: 2px;
  }

  @media screen and (max-width: 600px) {
    width: 18px;
    height: 18px;

    &:checked::after {
      width: 4px;
      height: 10px;
      top: 2px;
      left: 6px;
    }
  }
`;

InputContainer.displayName = 'InputContainer';

export const Label = styled.label`
    font-weight: 100;
    font-size: 16px;

    ${({ isRequired }) =>
        isRequired &&
        `&:after { content: "*"; color: #ed2144; font-weight: 300; }`}
`;

Label.displayName = 'Label';
