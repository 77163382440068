import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import translationKeys from '../../translations/keys';

import Span from '../span';
import ImageEnlargeOnHover from "../image-enlarge-on-hover";
import { Loading } from "./ImageLoader.styles";

/** Renders image if `src` prop is provided. Shows loading graphic if not. */
const ImageLoader = ({ width, height, src, alt, loadImage, timeout = 0, error, enlargeOnHover, placeholder, t, tReady }) => {

    useEffect(() => {
        if (!src && loadImage) {
            if (timeout) {
                const t = setTimeout(() => {
                    loadImage();
                }, timeout);

                return () => {
                    clearTimeout(t);
                };
            } else {
                loadImage();
            }
        }
    }, [loadImage, src, timeout]);

    if (error) {
        return (
            <Span
                block
                fontSize="80%"
                colour="red"
                light
                style={{
                    width,
                    minWidth: width,
                    textAlign: 'center',
                    textWrap: 'wrap'
                }}
            >
                {tReady ? t(translationKeys.common.NO_IMAGE) : 'No image'}
            </Span>
        );
    }

    if ((!src || src === 'error') && placeholder) {
        return placeholder;
    }
    // Show Loading until `src` is provided
    if (!src) {
        return <Loading $width={width} $height={height} data-testid="ImageLoader.Loading" />;
    }

    // Use ImageEnlargeOnHover component if requested
    if (enlargeOnHover) {
        return (
            <ImageEnlargeOnHover
                width={width}
                height={height}
                src={src}
                alt={alt}
            />
        );
    }

    // Otherwise just standard image
    return (
        <img
            width={width}
            height={height}
            src={src}
            alt={alt}
        />
    );
}

ImageLoader.propTypes = {
    /** Image width as string CSS or number of px. Currently only supports a numeric value as it is used in calculating animation values. */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    /** Image height as string CSS or number of px. */
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    /** Image src. */
    src: PropTypes.string,
    /** Image alt attribute. */
    alt: PropTypes.string.isRequired,
    /** Called at least once on mount if no `src` provided. May get called many times. */
    loadImage: PropTypes.func,
    /** Error occurred loading image so show fallback text. */
    error: PropTypes.bool,
    /** On mount, delay fetching image by this number of ms. For example. this is useful in virtualised lists when user may just be scrolling past. */
    timeout: PropTypes.number
};

export default withTranslation()(ImageLoader);