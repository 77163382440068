/**
 * Download file.
 * @param {Blob} fileData File contents.
 * @param {string} filename Name of downloaded file, including extension.
 */
export const downloadFile = (fileData, filename) => {
    // Code from https://stackoverflow.com/questions/32545632/how-can-i-download-a-file-using-window-fetch
    // and https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
    const url = window.URL.createObjectURL(fileData);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link); // SO answer says this step is required by Firefox
    link.click();
    link.remove();
    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(url);
    }, 100);
}

/**
 * @typedef FileToZip
 * @type {object}
 * @property {string} name Filename including extension.
 * @property {Date} lastModified Last modified date.
 * @property {Blob} input File contents.
 */

/**
 * Zip and download one or more files.
 * @param {FileToZip[]} files Files to zip.
 * @param {string} zipName Zip file name.
 */
export const downloadZip = (files, zipName) => {
    // Workers stored in public folder
    const zipWorker = new Worker('/workers/zip.worker.js');
    zipWorker.postMessage(files);
    zipWorker.onmessage = ({ data: blob }) => {
        downloadFile(
            blob,
            zipName
        );
    }
}