import styled, { keyframes } from 'styled-components';


// Scale that image enlarges to
export const SCALE = 3;


export const ImageContainer = styled.div`
    width: ${({ $width }) => typeof $width === 'number' ? `${$width}px` : $width};
    min-width: ${({ $width }) => typeof $width === 'number' ? `${$width}px` : $width};
    height: ${({ $height }) => typeof $height === 'number' ? `${$height}px` : $height};
    min-height: ${({ $height }) => typeof $height === 'number' ? `${$height}px` : $height};
    display: flex;
    justify-content: center;
`;

ImageContainer.displayName = 'ImageContainer';


export const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
    cursor: zoom-in;
`;

Image.displayName = 'Image';


const enlarge = keyframes`
0% {
    opacity: 1;
    transform: scale(1);
}

100%  {
    opacity: 1;
    transform: scale(${SCALE});
}
`;

export const EnlargedImage = styled.img`
    position: fixed;
    cursor: zoom-in;
    z-index: 600;
    animation: ${enlarge} .3s linear .3s 1 normal forwards;

    /* Image must be hidden during animation delay as it would look odd otherwise in situations where initial image is partially obscured */
    opacity: 0;
`;

EnlargedImage.displayName = 'EnlargedImage';