import PropTypes from 'prop-types';

import {
    InputContainer,
    Label,
    LabelAndInputContainer
} from './Checkbox.styles';

import Field from '../field';
import useWhiteLabelComponent from '../../../hooks/useWhiteLabelComponent';
import { isCCTVConnect } from '../../../utils/getHost';
import { VL_BLACK, WHITE } from '../../../utils/colours';

/** Specific component for checkboxes. Has its own label and comes wrapped in Field component (whereas other form components do not) due its unique layout. If you just want a checkbox without all this other stuff, use Input component with `type="checkbox"`. */
const Checkbox = ({
    onChange,
    name,
    label,
    errorMessage,
    description,
    value = false,
    large = true,
    required = false,
    hideRequired = false,
    hide = false,
    variant="dark",
    ...props
}) => {
    const { accentColour, accentColour2, useAccentColour2, whiteOverAccent } = useWhiteLabelComponent();

    return (
        <Field
            layout="vertical"
            fullWidth
            name={name}
            large={large}
            errorMessage={errorMessage}
            description={description}
            hide={hide}
            $noMTop={props.$noMTop}
        >
            <LabelAndInputContainer>
                <InputContainer
                    type="checkbox"
                    onChange={onChange}
                    name={name}
                    id={name}
                    error={errorMessage}
                    checked={value}
                    required={required}
                    aria-invalid={errorMessage ? 'true' : 'false'}
                    aria-describedby={
                        description ? name + '-description' : undefined
                    }
                    aria-disabled={props.disabled}
                    accentColour={isCCTVConnect ? variant === 'dark' ? VL_BLACK() : WHITE() : useAccentColour2 ? accentColour2 : accentColour}
                    whiteCheck={isCCTVConnect ? variant === 'dark' ? true : false : whiteOverAccent}
                    variant={variant}
                    {...props}
                />
                {label && (
                    <Label
                        htmlFor={name}
                        isRequired={!hideRequired && required}
                    >
                        {label}
                    </Label>
                )}
            </LabelAndInputContainer>
        </Field>
    );
};

Checkbox.propTypes = {
    /** Function to call whenever user changes the field's value. */
    onChange: PropTypes.func,
    /** Name of field. Used in `name` and `id` attribute. Must be unique to page. */
    name: PropTypes.string,
    /** Label text describing field. */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Error message to display when provided value is invalid. */
    errorMessage: PropTypes.string,
    /** Whether checked or not. */
    value: PropTypes.bool,
    /** Use larger size for spacing, error message, and description. */
    large: PropTypes.bool,
    /** Further information displayed next to field. */
    description: PropTypes.string,
    /** Whether this is a required field. */
    required: PropTypes.bool,
    /** Whether to visibly show in UI that this field is required (e.g. usually with an asterisk). Only applies when `required` is true. */
    hideRequired: PropTypes.bool,
    /** Do not render field. Used by our useForm custom hook. */
    hide: PropTypes.bool,
};

export default Checkbox;
