import { Fragment, memo } from "react";
import PropTypes from 'prop-types';

import { escapeRegExp } from "../../utils/string";
import { YELLOW_HIGHLIGHT } from "../../utils/colours";

import Span from "../span";

/** Renders text within which any instances of a search string will be highlighted. Case insensitive. */
const SearchableText = memo(({ searchString, text, Component = Span, ...props}) => {

    // If no search string then nothing needs highlighting. Just return text as is
    if (!searchString) {
        return <Component {...props}>{text}</Component>;
    }

    /*
        It is not as simple as just replacing any instances of `searchString` in `text` with `<Span>{searchString}</Span>`
        as we need to maintain the case of each letter.

        Instead we...
            1. Identify all instances of `searchString` in `text` (ignoring case)
            2. Split `text` by `searchString` leaving array of remaining parts of `text` that don't meet search condition
            3. Render first element of array, then first match (highlighted), then second element of array, then second match...
    */

    // Identify any parts of text that need highlighting
    const matches = text.match(new RegExp(escapeRegExp(searchString), 'ig'));

    return (
        <Component {...props}>
            {
                text
                // Split text into array
                .split(new RegExp(escapeRegExp(searchString), 'i'))
                // Render first element of array
                // Then before each subsequent array element we render the string matched above
                .map((string, index) => index === 0
                    // First element
                    ? string
                    // Render matched string and then array element
                    : <Fragment key={index}><mark style={{ background: YELLOW_HIGHLIGHT }}>{matches[index - 1]}</mark>{string}</Fragment>
                )
            }
        </Component>
    );
});

SearchableText.propTypes = {
    /** Search term to highlight. */
    searchString: PropTypes.string,
    /** Full text to show. */
    text: PropTypes.string.isRequired,
    /** Outermost component to use. Highlighted text will be rendered using Span component. */
    Component: PropTypes.elementType
};

export default SearchableText;