import { useState, useCallback, useEffect, useLayoutEffect } from "react";
import { usePopper } from 'react-popper';
import { useFocusVisible } from "react-aria";
import { isUsingTouchscreen } from "../../utils/pointer";

/**
 * Config to pass to usePopper. See https://popper.js.org/docs/v2/constructors for more info.
 * 
 * @typedef Config
 * @type {object}
 * @property {boolean} [fixedPositioning] Determines which usePopper positioning strategy to use ('fixed' or 'absolute').
 * @property {('auto', 'auto-start', 'auto-end', 'top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'right', 'right-start', 'right-end', 'left', 'left-start', 'left-end')} [placement] Placement of popup.
 * @property {boolean} [disabled] Whether trigger element is disabled.
 * @property {string|object} [children] Children of tooltip. Pass this in if children can change whilst tooltip is open, and hook will recalculate when that happens. If `children` is a node, ensure it is memoized.
 */

/**
 * @typedef TooltipProps
 * @type {object}
 * @property {boolean} show Is tooltip showing?
 * @property {tooltipRef} ref Callback ref for tooltip.
 * @property {object} style Styles to apply to tooltip.
 * @property {('top', 'bottom', 'left', 'right')} placement Placement of tooltip.
 * @property {object} arrowProps Props for arrow.
 * @property {arrowRef} arrowProps.ref Callback ref for arrow.
 * @property {object} arrowProps.style Styles to apply to arrow.
 * @property {number} arrowProps.size Size of arrow in px.
 */

/**
 * @callback closePopup
 */

/**
 * @callback tooltipRef
 */

/**
 * @callback arrowRef
 */

/**
 * @callback triggerRef
 */

/**
 * @typedef TriggerElementProps
 * @type {object}
 * @property {TriggerElementOnMouseEnter} onMouseEnter Function called when mouse hovers over element.
 * @property {TriggerElementOnMouseExit} onMouseExit Function called when mouse stops hovering over element.
 * @property {triggerRef} ref Callback ref for element.
 */

/**
 * @callback TriggerElementOnMouseEnter
 */

/**
 * @callback TriggerElementOnMouseExit
 */

/**
 * Custom hook for managing tooltip state and props.
 * Returns props to pass to the Tooltip component and to the component which triggers the tooltip on hover.
 * 
 * This does not currently support interactable tooltips (i.e. tooltips that contain clickable elements) as it will close
 * the moment the user is no longer hovering over trigger element. To support this case, we just need to add another state
 * `hoverTooltip` (similar to `hoverTriggerElement`) and show the tooltip if either the trigger element OR the tooltip is
 * being hovered over. Would probably need to hide it on a timeout as well as user will be briefly hovering over neither
 * element whilst they move from trigger to tooltip.
 * 
 * @param {Config} config Config to pass to usePopper.
 * @returns {[TooltipProps, TriggerElementProps]}
 */
const useTooltip = ({ fixedPositioning = false, placement = 'top', disabled, maxWidth, showOnMobile = true, children } = {}) => {

    const { isFocusVisible } = useFocusVisible();

    // Whether trigger element is being hovered over
    const [hoverTriggerElement, setHoverTriggerElement] = useState(false);

    // Whether trigger element is in focus
    const [focusTriggerElement, setFocusTriggerElement] = useState(false);

    // Make sure tooltip is hidden if trigger element becomes disabled
    // If user was hovering over element at the point it became disabled, onMouseExit will not get called
    useEffect(() => {
        if (disabled) {
            setHoverTriggerElement(false);
            setFocusTriggerElement(false);
        }
    }, [disabled]);

    // MouseEnter function for trigger element
    const onTriggerElementMouseEnter = useCallback(() => setHoverTriggerElement(true), []);

    // MouseExit function for trigger element
    const onTriggerElementMouseLeave = useCallback(() => setHoverTriggerElement(false), []);

    // onFocus function for trigger element
    const onTriggerElementFocus = useCallback(() => setFocusTriggerElement(true), []);

    // onBlur function for trigger element
    const onTriggerElementBlur = useCallback(() => setFocusTriggerElement(false), []);

    // Callback refs for trigger element, tooltip container and arrow
    const [triggerElement, _setTriggerElement] = useState(null);
    const setTriggerElement = useCallback((element) => {
        // Make sure state gets reset when element is removed
        if (!element) {
            setHoverTriggerElement(false);
            setFocusTriggerElement(false);
        }
        _setTriggerElement(element);
    }, []);
    const [tooltipContainerElement, setTooltipContainerElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);

    // usePopper handles positioning the tooltip
    const { styles, attributes, update } = usePopper(triggerElement, tooltipContainerElement, {
        modifiers: [
            { name: 'arrow',
                options: {
                    element: arrowElement,
                    // Allow for rounded borders of tooltip
                    padding:  6
                }
            },
            {
                name: "offset",
                options: {
                    offset: [0, ARROW_SIZE]
                }
            },
            
            // Prevent tooltip from touching screen edge
            {
                name: 'preventOverflow',
                options: {
                    // altAxis: true,
                    padding: 20
                },
            }
        ],
        placement,
        strategy: fixedPositioning ? 'fixed' : 'absolute'
    });

    // Need to recalculate tooltip size and position if children changes whilst it's open
    useLayoutEffect(() => {
        if (children && update) {
            update();
        }
    }, [children, update]);

    return [
        // Tooltip props
        {
            // Show tooltip if mouse is hovering over it
            // OR keyboard user has focused it
            show: (!showOnMobile && isUsingTouchscreen()) ? false : hoverTriggerElement || (focusTriggerElement && isFocusVisible),
            ref: setTooltipContainerElement,
            style: styles.popper,
            placement: attributes?.popper?.['data-popper-placement'],
            arrowProps: {
                ref: setArrowElement,
                style: styles.arrow,
                size: ARROW_SIZE
            },
            maxWidth: maxWidth
        },

        // Trigger element props
        {
            onMouseEnter: onTriggerElementMouseEnter,
            onMouseLeave: onTriggerElementMouseLeave,
            onFocus: onTriggerElementFocus,
            onBlur: onTriggerElementBlur,
            ref: setTriggerElement,
            disabled
        },

        update
    ];
};

// In px
const ARROW_SIZE = 8;

export default useTooltip;