import _ from 'lodash';
import EventSource from 'eventsource'; // Need this so that we can use /cameras/realtime/live API with Authorization in the header

import {
    getUiddsPerLogger,
} from './helpers';
import { getEventObject } from './events';
import { logCameraTaskSent } from './athena';
import { getAuthToken } from './managers/store';
import { makePrivateRequestAsUser, makePrivateRequestWithData, privateAuthenticatorRequest, privateLoggerRequest } from './managers/requestBuilder';

import { logError } from '../utils/errors';

// Helper functions

// Takes object returned from /cameras/status or realtime API as only argument
// Returns object containing only the properties the app needs
// May rename or reformat some properties too
const getStatusObject = ({ lastthumb, status, live, locallive, uidd, lastactivity, laststill, liveStreamName, wowza }) => ({
    // Time of last thumbnail image
    lastThumbTime: lastthumb,

    // Time of last camera activity
    lastActivity: lastactivity,

    // Time of last still image,
    lastStill: laststill * 1000,

    // Status 'live' means the camera is online but not necessarily streaming live
    // Renaming this status to 'online' so there is a clear distinction between camera status and whether an online camera is live streaming
    status: status === 'live' ? 'online' : status,

    // Whether camera is sending a live stream (to Wowza)
    liveStreaming: !!live,

    // Whether camera is sending a live stream to local live server
    localLiveStreaming: !!locallive,

    // Stream name to use for live video
    liveStreamName,
    wowza,

    uidd,
    deviceId: parseInt(uidd.split('.')[1]),
});

// Takes object returned by /viewerInfo for one camera
// Returns object containing only the properties the app needs
const getCameraObject = ({
    logger,
    uidd,
    wowza,
    phonename,
    cloudRecordingEnabled,
    analyticsEnabled,
    analyticsScheme,
    audioEnabled,
    permissions,
    romEnabled,
    ptzEnabled,
    mainstreamLive,
	tags,
	adaptrCloudId,
    appVersion,
    locallivehost,
    localliveid,
    macAddress,
    phoneid,
    recordingResolution,
    model,
    timeZoneName,
    streamname,
    vcodec
}) => {

    const [owner, deviceId] = uidd.split('.');
    
    return {
        logger,
        uidd,
        owner: parseInt(owner),
        deviceId: parseInt(deviceId),
        model,
        cloudRecordingEnabled: !!cloudRecordingEnabled,
        romEnabled,
        ptzEnabled,
        audioEnabled,
        recordingResolution,
        wowza,
        name: phonename,
        analyticsEnabled: !!analyticsEnabled,
        analyticsScheme,
        // If array contains 'a', they have admin access
        // Else if array contains 'v', treat them as view only even though they have 'r' in there as well ('v' is frontend only restriction)
        access: permissions ? (['a', 'v', 'r', 'l'].find(permission => permissions.includes(permission))) : 'a',
		tags: tags ?? [],
    	cloudAdapterId: adaptrCloudId,
        cloudAdapterVersion: appVersion,
        // Local live
        localLiveHost: locallivehost,
        localLiveId: localliveid,
        // install info
        macAddress: macAddress,
        camId: phoneid,
        timezoneName: timeZoneName,
        // Stream name to use for recorded video
        recordedStreamName: streamname,
        mainstreamLive: !!mainstreamLive,
        videoCodec: vcodec
    };
}

// APIs

// Send command to a camera (e.g. 'livecommand')
/**
 * Send a camera task.
 * @param {string} logger Camera logger.
 * @param {string} uidd Camera uidd.
 * @param {string} command Action.
 * @returns 
 */
export const sendCameraTask = async (logger, uidd, command) => {
    return await privateLoggerRequest(
        logger,
        'GET',
        '/sendcameratask', {
        params: {
            uid: uidd,
            action: command,
            caller: 'web'
        },
    });
};


// Make request to /viewerInfo
// Can specify certain uidds or leave it undefined which means all cameras that the user has access to
// Returns object mapping uidd to info
// If user does not have access to a specified camera or it has been deleted, it will not be included in response object
const fetchCameras = async ({ makePrivateRequest }, uidds) => {
    let cameras = {};
    try {
        cameras = (
            await makePrivateRequest(
                'auth',
                'GET',
                '/devices/viewerInfo',
                {
                    params: uidds ? { uidd: uidds } : undefined
                }
            )
        ).data?.result;
    } catch (error) {
        if (error.response?.status !== 404) {
            throw error;
        }
    }

    return _.reduce(cameras, (acc, { devices }) => {
        return {
            ...acc,
            ..._.chain(devices).mapKeys(({ uidd }) => uidd).mapValues(getCameraObject).value()
        }
    }, {});    
}

const getCamerasByOwnerRequest = async (requestData, ownerUid) => {
    // Fetch info on all cameras and then filter by owner
    // Ideally at some point we create a new API for this purpose
    const allCameras = await fetchCameras(requestData);

    return _.chain(allCameras).pickBy(({ owner }) => owner === ownerUid).mapKeys(({ deviceId }) => deviceId).value();
};

/**
 * Fetch all cameras owned by a user.
 * 
 * @param {*} ownerUid 
 * @returns {object<string, object>} Object with device ids as keys and values are objects containing properties of camera.
 */
export const getCamerasByOwner = makePrivateRequestAsUser(getCamerasByOwnerRequest);

export const getCamerasByOwnerUsingRequestData = data => makePrivateRequestWithData(data, getCamerasByOwnerRequest);

/**
 * Fetch info on array of cameras, specified by `uidds` array argument
 * Returns object with uidds as keys and values are objects containing properties of camera.
 * If user does not have access to a specified camera (or it doesn't exist), then it will be left out of the returned object.
 * 
 * @param {string[]} uidds Uidds of cameras we want info for.
 * @returns {object<string, object>} Object mapping uidd to info.
 */
export const getCameras = uidds => makePrivateRequestAsUser(fetchCameras)(uidds);

/**
 * 
 * @returns {object} All cameras that a user has access to.
 */
export const getAllCameras = makePrivateRequestAsUser(fetchCameras);

/**
 * When we no longer use wowza, switch /viewerInfo requests to use this new faster API instead.
 * When requiring this info for live viewing, do not use this API.
 * @returns {object<string, object>} All cameras that a user has access to, keyed by uidd.
 */
// export const getAllCamerasWithoutLiveViewing = makePrivateRequestAsUser(async ({ makePrivateRequest }) => {
    
//     const cameras = (
//         await makePrivateRequest(
//             'auth',
//             'GET',
//             '/cameras'
//         )
//     ).data;

//     return cameras.reduce(
//         (
//             acc,
//             {
//                 uid,
//                 id,
//                 model,
//                 cloud_recording_enabled,
//                 rom,
//                 ptz_enabled,
//                 audio_enabled,
//                 recording_resolution,
//                 streamname,
//                 wowza,
//                 phonename,
//                 analytics_enabled,
//                 permission,
//                 tags,
//                 adaptr_cloud_id,
//                 local_live_id,
//                 local_live_host,
//                 mac_address,
//                 phoneid
//             }
//         ) => {
//             const uidd = `${uid}.${id}`;

//             return {
//                 ...acc,
//                 [uidd]: {
//                     uidd,
//                     owner: uid,
//                     deviceId: id,
//                     model,
//                     cloudRecordingEnabled: !!cloud_recording_enabled,
//                     romEnabled: !!rom,
//                     ptzEnabled: !!ptz_enabled,
//                     audioEnabled: !!audio_enabled,
//                     recordingResolution: recording_resolution,
//                     streamName: streamname,
//                     wowza,
//                     name: phonename,
//                     analyticsEnabled: !!analytics_enabled,
//                     // If permission is null, they are owner
//                     access: permission ?? 'a',
//                     tags: tags ?? [],
//                     cloudAdapterId: adaptr_cloud_id,
//                     // Local live
//                     localLiveHost: local_live_host,
//                     localLiveId: local_live_id,
//                     // install info
//                     macAddress: mac_address,
//                     camId: phoneid
//                 }
//             };
//         },
//         {}
//     ); 
// });



// Returns object where keys are uidds and values are objects created using `getStatusObject` above
// uiddsAndLoggers is array of objects each containing keys 'uidd' and 'logger', e.g. [{ uidd: 1, logger: '...' }]
export const getCamerasStatus = async (uiddsAndLoggers) => {

    const uiddsPerLogger = getUiddsPerLogger(uiddsAndLoggers);

    // Don't want to exceed max URL length so set a max query string length comfortably below that
    const MAX_QUERY_STRING_LENGTH = 1800;

    const results = await Promise.all(
        Object.entries(uiddsPerLogger).map(([logger, uidds]) => {

            // Ideally we make one request per logger but if we have too many uidds for a logger we must break it into multiple requests

            // Get length that each uidd will occupy (i.e. `&uidd=${uidd}`.length)
            const paramLengths = uidds.map(uidd => uidd.length + `&uidd=`.length);

            // Divide uidds into groups such that each group's string will be no longer than maximum
            // Iterate over each item in `paramLengths`, and either add to current group, or create new group if needed
            const paramGroups = paramLengths.slice(1).reduce((acc, paramLength) => {
                const currentGroup = acc[acc.length - 1];
                if (currentGroup.length + paramLength > MAX_QUERY_STRING_LENGTH) {
                    // Create new group as this param would take string over length limit
                    return acc.concat({ count: 1, length: paramLength, startIndex: currentGroup.startIndex + currentGroup.count });
                } else {
                    // There's still room in current group so just add to this one
                    currentGroup.count++;
                    currentGroup.length += paramLength;
                    return acc;
                }
            }, [{ count: 1, length: paramLengths[0], startIndex: 0 }]);

            // Make request for each group
            return paramGroups.map(({ startIndex, count }) => privateLoggerRequest(logger, 'GET', '/cameras/status', {
                params: {
                    uidd: uidds.slice(startIndex, startIndex + count),
                },
            }));
        }).flat()
    );

    return results
        .map(({ data: { result, errors } }) => {
            const camerasStatus = {};

            if (Object.keys(result).length > 0) {
                _.forEach(result, ({ devices }) => {
                    _.forEach(devices, (data) => {
                        camerasStatus[data.uidd] = getStatusObject(data);
                    });
                });
            }

            // Handle 'notResident' errors
            // Can treat them as status 'offline'
            if (errors?.length > 0) {
                errors.forEach((obj) => {
                    const uidd = Object.keys(obj)[0];
                    camerasStatus[uidd] = getStatusObject({ status: 'offline', uidd });
                });
            }

            return camerasStatus;
        })
        .reduce((acc, obj) => ({ ...acc, ...obj }), {});
};

// Send command to camera to start/keep live streaming
// Cameras stop live streaming if they haven't received this command in last ~1 min
export const tellCameraToSendLiveVideo = async (logger, uidd, localLive = false) => {
    const command = localLive ? 'locallivecommand' : 'livecommand';
    logCameraTaskSent(uidd, command);
    return await sendCameraTask(logger, uidd, command);
};

// Send command to camera to start/keep sending live thumbs
// Cameras stop live streaming if they haven't received this command in last ~1 min
export const tellCameraToSendLiveThumbs = async (logger, uidd) => {
    return await sendCameraTask(logger, uidd, 'motionthumbscommand');
};

// Create realtime link with all relevant loggers to receive camera status events
// uiddsAndLoggers is array of objects each containing keys 'uidd' and 'logger', e.g. [{ uidd: 1, logger: '...' }]
// handleMessage is a function which is called whenever an event is received. It gets passed object created from `getStatusObject` (live session) or `getEventObject` (events)
// Returns cleanup function to kill all EventSources
const LIVE = 'liveSession',
    EVENT = 'alert';
export const createLiveSessionRealtimeLinkWithLoggers = (
    uiddsAndLoggers,
    handleMessage
) => createRealtimeLinkWithLoggers(uiddsAndLoggers, handleMessage, LIVE);
export const createEventsRealtimeLinkWithLoggers = (
    uiddsAndLoggers,
    handleMessage
) =>
    createRealtimeLinkWithLoggers(uiddsAndLoggers, handleMessage, EVENT);
const createRealtimeLinkWithLoggers = (
    uiddsAndLoggers,
    handleMessage,
    type
) => {
    // Create realtime link with one logger for specified cameras
    // Backoff is time (in ms) we wait if a link fails before trying to create a new one
    // Initial backoff is 1000ms
    const createRealtimeLinkWithLogger = (logger, uidds, addEventSource, addBackoffTimeout, backoff = 1000) => {
        // Max URL length is 2048 characters so to be safe let's limit query params (excluding 'type') to 1800 characters
        // If we exceed that length, use user uids rather than device uidds
        let uidParam = uidds.map((uidd) => `uidd=${uidd}`).join('&');
        if (uidParam.length > 1800) {
            uidParam = uidds
                .reduce((acc, uidd) => {
                    const uid = uidd.split('.')[0];
                    return acc.includes(uid) ? acc : acc.concat(uid);
                }, [])
                .map((uid) => `uids=${uid}`)
                .join('&');
        }

        const source = new EventSource(
            `https://${logger}/cameras/realtime/live?types=${type}&${uidParam}`,
            { headers: { Authorization: 'ManythingToken ' + getAuthToken() } }
        );

        // Runs when message received
        source.onmessage = (event) => {
            const message = JSON.parse(event.data);

            if (message?.error) {
                console.error(`Realtime link to ${logger} sent error message`);
                console.error(message.error);
            } else if (
                message?.data?.uidd &&
                uidds.includes(message.data.uidd)
            ) {
                if (message.type === LIVE) {
                    // Pass live session event data to handleMessage function
                    handleMessage({
                        ...getStatusObject(message.data),
                        type: LIVE,
                    });
                } else if (message.type === EVENT) {
                    // Pass alert event data to handleMessage function
                    handleMessage({
                        ...getEventObject(message.data),
                        type: 'event',
                        logger,
                    });
                }
            }
        };

        // Runs when error occurs
        source.onerror = (error) => {
            // Error occured either during creation of link or whilst it was open
            // Link can no longer be used
            // Close it and open new one
            console.error(`Realtime link to ${logger} failed`);
            logError(error, { identifier: 'SSE', logger });

            // Must close link before creating a new one
            source.close();

            // After time specified in backoff, try to create a new link
            addBackoffTimeout(
                setTimeout(() => {
                    // Double backoff on each failure, up to maximum of 60000ms
                    createRealtimeLinkWithLogger(
                        logger,
                        uidds,
                        addEventSource,
                        addBackoffTimeout,
                        Math.min(backoff * 2, 60000)
                    );
                }, backoff)
            );
        };

        // Must pass all opened event sources to addEventSource so caller can close them when necessary
        addEventSource(source);
    };

    // Group cameras by logger
    // We create one link per logger and it sends us events about the cameras we ask for
    const uiddsPerLogger = getUiddsPerLogger(uiddsAndLoggers);

    // Event sources
    const sources = [];

    // Timeouts created to set up new links on error
    // When `killAllLinks` is called, these must also be cleared to make sure no new links get created
    const timeouts = [];

    // Create a link for each logger
    for (const logger in uiddsPerLogger) {
        createRealtimeLinkWithLogger(logger, uiddsPerLogger[logger], source => sources.push(source), timeout => timeouts.push(timeout));
    }

    // Return cleanup function to kill all EventSources
    const killAllLinks = () => {
        timeouts.forEach((timeout) => clearTimeout(timeout));
        sources.forEach((source) => source.close());
    };

    return killAllLinks;
};

/**
 * Updates tags for multiple cameras at once. The tags for each camera will be completely overwritten by the array sent in this request.
 * @param {Object} newTags - object mapping camera uidds to new tag array, e.g. { '123.1': ['office', 'parking'], '456.3': [ 'office', 'garden' ]}
 * @return {import('axios').AxiosPromise}
 */
export const updateTags = (newTags) => {
    return privateAuthenticatorRequest('PUT', `/cameras/tags`, {
        data: {
            // Array of uidds being edited
            uidd: Object.keys(newTags),

            // changes must be formatted like [{ uidd: '123.1', tags: ['office', 'parking']}, { uidd: '456.3', tags: ['office', 'garden']}]
            changes: Object.entries(newTags).map(([uidd, tags]) => ({ uidd, tags }))
        }
    });
}

// Fetches all distinct tags that have been applied to either a specific set of cameras or any camera the user has access to
// `uidds` is either array of string uidds, or leave undefined to mean all cameras
export const getAllTags = async (uidds) => {

    const { data } = await privateAuthenticatorRequest('GET', `/cameras/tags`, {
        params: {
            uidd: uidds
        }
    });
    
    return data;
}

// Command must be one of 'up', 'down', 'left', 'right, 'in' or 'out'
export const sendCameraPTZCommand = (logger, uidd, command) =>
    sendCameraTask(logger, uidd, `ptz_${command}`);

// Returns base64
export const getLastThumb = async (logger, uidd) => {
    const { data } = await privateLoggerRequest(
        logger,
        'GET',
        `/getalertthumb/${uidd}`,
        {
            responseType: 'arraybuffer',
            params: {
                // Cache bust to make sure we get most recent thumb
                t: new Date().getTime()
            }
        }
    );

    return Buffer.from(data, 'binary').toString('base64');
};

/**
 * Get first still uploaded after a specified time.
 * @param {string} logger Logger to make request to.
 * @param {string} uidd Camera uidd.
 * @param {number} fromTime Timestamp in ms.
 * @returns
 */
export const getFirstStillAfterTime = async (logger, uidd, fromTime) => {
    const [ownerUid, deviceId] = uidd.split('.');

    const { data } = await privateLoggerRequest(
        logger,
        'GET',
        `/still/${ownerUid}/${deviceId}`,
        {
            params: {
                after: fromTime
            },
            responseType: 'blob'
        }
    );

	return data;
}

/**
 * Get SD still for a camera.
 * @param {string} logger Logger to make request to.
 * @param {string} uidd Camera uidd.
 * @param {number} time Still timestamp in ms.
 * @returns 
 */
export const getSDStill = async (logger, uidd, time) => {
    const { data } = await privateLoggerRequest(
        logger,
        'GET',
        // Token can be passed in header but path needs something in its place
        `/getstill/${uidd}/${time / 1000}/token`,
        {
            responseType: 'blob'
        }
    );

    return data;
}


/* -------------------------------- Settings -------------------------------- */

// This is actually only a soft delete (sets is_camera to false in device table)
export const deleteCamera = (uidd) => {
    return privateAuthenticatorRequest('GET', '/deldevice', {
        params: {
            uid: uidd,
        },
    });
};

export const deregisterCamerasFromCloudId = (cloudId, deviceIds) => {
    return privateAuthenticatorRequest(
        'DELETE', 
        `/users/:uid/adaptr/${cloudId}`,
        {
            data: {
                deviceIds: deviceIds
            }
        }
    );
};

export const uninstallCamera = (logger, uidd, all = false) => {
    let command = 'uninstall';
    if (all) {
        command += '_all';
    }
    return sendCameraTask(logger, uidd, command);
};

export const tellAdapterThatCameraSettingsHaveChanged = (logger, uidd) => {
    return sendCameraTask(logger, uidd, 'getconfig');
};

export const changeCameraName = (uidd, newName) => {
    return privateAuthenticatorRequest('PUT', '/phonename', {
        params: {
            uid: uidd,
            name: newName,
            token: getAuthToken(),
        },
    });
};

/**
 * Enabled/disable cloud recording for a camera. 
 * @param {string} uidd Camera uidd.
 * @param {boolean} cloudRecordingEnabled Whether to enable or disable cloud recording.
 * @returns 
 */
export const changeCameraCloudRecordingEnabled = async (uidd, cloudRecordingEnabled) => {
    const [ownerId, deviceId] = uidd.split('.');

    const { data } = await privateAuthenticatorRequest(
        'PUT',
        `/simple/${ownerId}/${deviceId}/config/cloudrecordingenabled`,
        {
            data: {
                token: getAuthToken(),
                cloudRecordingEnabled
            }
        }
    );
    
    // This is one of those stupid APIs that returns a 200 if it doesn't work
    if (data.err) {
        throw new Error(data.err);
    }

    return data;
}

/**
 * Enabled/disable analytics for a camera.
 * 
 * @param {string} uidd Camera uidd.
 * @param {boolean} analyticsEnabled Whether to enable or disable analytics. 
 * @returns 
 */
export const changeCameraAnalyticsEnabled = (uidd, analyticsEnabled) => {

    const [ownerId, deviceId] = uidd.split('.');

    return privateAuthenticatorRequest(
        'PUT',
        `/camera/${ownerId}/${deviceId}/config/analytics`,
        {
            data: {
                enabled: analyticsEnabled
            }
        }
    );
}

/**
 * Change camera recording mode to rom/continuous.
 * @param {string} uidd Camera Uidd.
 * @param {('rom', 'continuous')} recordingMode Rom or continuous.
 * @returns
 */
export const changeCameraRecordingMode = (uidd, recordingMode) => {
    const [ownerId, deviceId] = uidd.split('.');

    return privateAuthenticatorRequest(
        'PUT',
        `/camera/${ownerId}/${deviceId}/config/recordingmode`,
        {
            data: {
                mode: recordingMode
            }
        }
    );
}

export const RESOLUTION_TO_NVRMODE_MAP = {
    'SD': 1,
    '2MP': 0,
    '4MP': 3,
    '8MP': 2
};

/**
 * Change camera recorded video quality.
 * @param {string} uidd Camera uidd.
 * @param {('SD', '2MP', '4MP', '8MP')} resolution Recorded video quality.
 * @returns 
 */
export const changeCameraRecordingResolution = (uidd, resolution) => {
    const [ownerId, deviceId] = uidd.split('.');

    return privateAuthenticatorRequest(
        'PUT',
        `/simple/${ownerId}/${deviceId}/config/nvrmode`,
        {
            headers: {
                'api-version': '2.0.0'
            },
            data: {
                nvrmode: RESOLUTION_TO_NVRMODE_MAP[resolution]
            }
        }
    );
}